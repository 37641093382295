@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Oswald:wght@400;500&display=swap');

@tailwind base;

@layer base {
    body {
        @apply font-body bg-blue-900;
    }

    h1 {
        @apply font-display text-blue-900 text-5xl font-normal;
    }

    h2 {
        @apply font-display text-blue-900  text-4xl font-normal;
    }

    h3 {
        @apply font-display text-blue-900  text-2xl font-bold;
    }

    h4 {
        @apply font-display text-blue-900  text-xl font-bold;
    }

    a {
        @apply text-blue-600 underline;
    }

    p {
        @apply mb-6 leading-7;
    }

    ul {
        list-style: disc;
    }

    ul li {
        @apply ml-6 mb-2 leading-6;
    }

    .logo {
        max-width: 240px;
    }
    
    @media only screen and (max-width: 767px) {
        .logo {
            max-width: 200px;
        }
    }

    .btn {
        @apply rounded bg-white hover:bg-gray-100 text-gray-700 hover:text-black py-2 px-4 shadow-md hover:shadow-lg no-underline;
    }

    .btn--dark {
        @apply bg-blue-900 text-white hover:bg-blue-800 hover:text-white;
    }

    .btn--lg {
        @apply text-lg py-3;
    }

    .btn--dark {
        @apply inline-flex items-center;
    }

    .btn[disabled] {
        @apply bg-gray-300 text-gray-500 cursor-not-allowed;
    }
}

@tailwind components;

@tailwind utilities;
